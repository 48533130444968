import { CreateNewsInput, UpdateNewsInput } from "src/app/shared/graphql/generated/graphql";

export namespace News {
  export class GetNewsList {
    static readonly type = '[News] GetNewsList';
  }

  export class CreateNews {
    static readonly type = '[News] CreateNews';
    constructor(public createNewsInput: CreateNewsInput) { }
  }

  export class UpdateNews {
    static readonly type = '[News] UpdateNews';
    constructor(public updateNewsInput: UpdateNewsInput) { }
  }

  export class SelectNewsById {
    static readonly type = '[News] SelectNews';
    constructor(public id: number) { }
  }

  export class UnsetSelectedNews {
    static readonly type = '[News] UnsetSelectNews';
  }

  export class UnsetRecentNews {
    static readonly type = '[News] UnsetRecentNews';
  }

  export class ToggleActive {
    static readonly type = '[News] ToggleActive';
    constructor(public id: number | null) { }
  }

  export class RemoveNews {
    static readonly type = '[News] RemoveNews';
    constructor(public newsId: number) { }
  }

}
