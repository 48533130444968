import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AuthStateModel } from './auth.model';
import { Injectable } from '@angular/core';
import { Auth } from './auth.action';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { CurrentUser } from '../current_user/current_user.action';
import { AUTH_STATE } from '../index';
import { Products } from '../products/products.action';
import { Storage } from '../storage/storage.actions';
import { StorageState } from '../storage/storage.state';
import {
  isTokenValid,
} from './auth.utils';
import { AuthService } from '../../shared/services/auth.service';
import { Navigate } from '@ngxs/router-plugin';
import { AdminUserAction } from 'src/app/administration/state/user/admin-user.action';
import { AdminOrgAction } from 'src/app/administration/state/org/admin-org.action';
import { SpecialAnalysisAction } from '../special_analysis/special_analysis.action';
import { LocalDatasetsAction } from '../local_datasets/local_datasets.action';
import { SettingsAction } from '../settings/settings.action';
import { News } from '../news/news.action';
import { NewsCategories } from '../news_categories/news_categories.action';
import { ErrorCodeService } from 'src/app/shared/services/error-code.service';
import { IAccessibleDataset, IAccessibleDatasetData } from '../../shared/model/accessible-dataset.interface';
import { Apollo } from 'apollo-angular';
import { CurrentUserState } from '../current_user/current_user.state';
import { UserService } from 'src/app/shared/services/user.service';

@State<AuthStateModel>({
  name: AUTH_STATE,
  defaults: {
    isLoggedIn: false,
    accessToken: undefined,
    userClaims: [],
    accessibleDatasets: new Map<string, IAccessibleDatasetData>(),
    prevFailedUrl: '',
    rememberMe: false,
    isLoggingOut: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private store: Store,
    private errorCodeService: ErrorCodeService,
    private apollo: Apollo,
    private userService: UserService
  ) {}

  @Selector()
  static isLoggedIn(state: AuthStateModel) {
    return state.isLoggedIn;
  }

  @Selector()
  static userClaims(state: AuthStateModel) {
    return state.userClaims;
  }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static isUserAdmin(state: AuthStateModel) {
    return state.userClaims.includes('admin_access');
  }

  @Selector()
  static isUserOrgAdmin(state: AuthStateModel) {
    return state.userClaims.includes('administer_own_organisation');
  }

  @Selector()
  static isLoggingOut(state: AuthStateModel) {
    return state.isLoggingOut;
  }

  static hasClaims(claims: string[]) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return claims.every((claim) => state.userClaims.includes(claim));
    });
  }

  static hasClaim(claim: string) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.userClaims.includes(claim);
    });
  }

  static accessibleDataset(datasetId: string) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.accessibleDatasets.get(datasetId);
    });
  }

  @Selector()
  static accessibleDatasets(state: AuthStateModel) {
    return state.accessibleDatasets;
  }

  @Selector()
  static prevFailedUrl(state: AuthStateModel) {
    return state.prevFailedUrl;
  }

  @Selector()
  static rememberMe(state: AuthStateModel) {
    return state.rememberMe;
  }

  @Action(Auth.Initialize)
  async initializeAuth({
    getState,
    dispatch,
    patchState,
  }: StateContext<AuthStateModel>): Promise<void> {
    if (getState().isLoggedIn) {
      return;
    }

    const accessToken =
      getState().accessToken ||
      this.store.selectSnapshot(StorageState.accessToken);

    if (isTokenValid(accessToken)) {
      dispatch(Auth.LoginSuccess);

      return;
    }
    await lastValueFrom(
      dispatch(Auth.RefreshTokens).pipe(
        tap({
          next: () => {
            const renewedAccessToken = getState().accessToken;

            // Logout when the refresh attempt still result in an invalid token
            if (!isTokenValid(renewedAccessToken)) {
              dispatch(Auth.Logout);
              return;
            }

            dispatch(Auth.LoginSuccess);
          },
        })
      )
    );
  }

  @Action(Auth.Activateaccount)
  activateAccount(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { token, password }: Auth.Activateaccount
  ): Observable<any> {
    return this.authService.activateAccount(token, password).pipe(
      tap({
        next: (response) => {
          if (response) {
            dispatch(
              new Auth.UpdateTokens({
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
              })
            );
            dispatch(new Auth.LoginSuccess());
          }
        },
      })
    );
  }

  @Action(Auth.Login)
  login(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { email, password, rememberMe }: Auth.Login
  ): Observable<any> {
    patchState({ rememberMe });
    dispatch(new Storage.RememberMe(rememberMe));

    return this.authService.login({ email, password }).pipe(
      tap({
        next: (loginResponse) => {
          if (loginResponse) {
            dispatch(
              new Auth.UpdateTokens({
                accessToken: loginResponse.accessToken,
                refreshToken: loginResponse.refreshToken,
              })
            );
            dispatch(new Auth.LoginSuccess());
          }
        },
        error: (error) => {
          const errCode = this.errorCodeService.getErrorCode(error);
          const errMsg = this.errorCodeService.getErrorMessage(errCode);

          throw errMsg;
        },
      })
    );
  }

  @Action(Auth.LoginSuccess)
  loginSuccess({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      isLoggedIn: true,
    });
    dispatch(CurrentUser.LoadUser);
    dispatch(Products.Initialize);
    dispatch(LocalDatasetsAction.Initialize);
    dispatch(SpecialAnalysisAction.LoadSpecialAnalyses);
    dispatch(SettingsAction.LoadCurrentUserFilterSettings);
    dispatch(News.GetNewsList);
    dispatch(NewsCategories.GetNewsCategories);
  }

  @Action(Auth.RefreshTokens)
  async refreshToken(
    { patchState, dispatch, getState }: StateContext<AuthStateModel>,
    { refreshToken }: Auth.RefreshTokens
  ): Promise<any> {
    const refreshTokenFromArg = refreshToken;
    const refreshTokenFromState = getState().refreshToken;
    const refreshTokenFromStorage = this.store.selectSnapshot(
      StorageState.refreshToken
    );
    const existingRefreshToken =
      refreshTokenFromArg || refreshTokenFromState || refreshTokenFromStorage;

    if (!existingRefreshToken || !isTokenValid(existingRefreshToken)) {
      return;
    }

    return lastValueFrom(
      this.authService.refreshTokens(existingRefreshToken).pipe(
        tap({
          next: (tokenPair) => {
            if (tokenPair) {
              dispatch(new Auth.UpdateTokens(tokenPair));
            }
          },
          error: (error) => {
            console.error(error);
            dispatch(Auth.Logout);
          },
        })
      )
    );
  }

  @Action(Auth.Logout)
  logout({ patchState, dispatch }: StateContext<AuthStateModel>) {
    this.apollo.client.clearStore();
    patchState({
      isLoggedIn: false,
      accessToken: undefined,
      refreshToken: undefined,
      userClaims: [],
      accessibleDatasets: new Map<string, IAccessibleDataset>(),
      isLoggingOut: true,
    });
    dispatch(new Storage.ClearAccessRefreshToken());
    dispatch(new Navigate(['/login']));
  }

  @Action(Auth.UpdateTokens)
  updateToken(
    { patchState, dispatch, getState }: StateContext<AuthStateModel>,
    action: Auth.UpdateTokens
  ) {
    const {
      tokenPair: { accessToken, refreshToken },
    } = action;

    patchState({
      accessToken,
      refreshToken,
    });

    // accessToken need to be persisted so the app can keep authenticated state on page reload
    dispatch(new Storage.SetAccessToken(accessToken));

    if (getState().rememberMe) {
      dispatch(new Storage.SetRefreshToken(refreshToken));
    }
  }

  @Action(Auth.SetNewPassword)
  setNewPassword(
    { patchState, dispatch }: StateContext<AuthStateModel>,
    { token, password }: Auth.SetNewPassword
  ): Observable<any> {
    return this.authService.resetPassword(token, password).pipe(
      tap({
        next: (response) => {
          if (response) {
            dispatch(
              new Auth.UpdateTokens({
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
              })
            );
            dispatch(new Auth.LoginSuccess());
          }
        },
      })
    );
  }

  @Action(Auth.NavigationFailed)
  setFailedNavUrl(
    ctx: StateContext<AuthStateModel>,
    action: Auth.NavigationFailed
  ) {
    ctx.patchState({
      prevFailedUrl: action.url,
    });
  }

  @Action(Auth.UpdateClaimsAndDatasetsPermission)
  updateClaimsAndDatasetsPermission(
    ctx: StateContext<AuthStateModel>,
    action: Auth.UpdateClaimsAndDatasetsPermission
  ) {
    ctx.patchState({
      userClaims: action.claims,
      accessibleDatasets: action.accessibleDatasets,
    });

    if (this.store.selectSnapshot(AuthState.isUserAdmin)) {
      ctx.dispatch(AdminUserAction.LoadUsers);
      ctx.dispatch(AdminOrgAction.LoadOrgs);
    }
  }
}
