import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NewsCategoryStateModel } from './news_categories.model';
import { NEWS_CATEGORIES_STATE } from '..';
import { Injectable } from '@angular/core';
import { NewsCategoryService } from 'src/app/shared/services/news-categories.service';
import { NewsCategories } from './news_categories.action';
import { tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UpdateNewsCategoryInput } from 'src/app/shared/graphql/generated/graphql';

@State<NewsCategoryStateModel>({
  name: NEWS_CATEGORIES_STATE,
  defaults: {
    newsCategories: [],
    fetching: false,
  },
})
@Injectable()
export class NewsCategoriesState {
  constructor(
    private router: Router,
    private newsCategoriesService: NewsCategoryService,
    private messageService: MessageService,
  ) {}

  @Selector()
  static newsCategories(state: NewsCategoryStateModel) {
    return state.newsCategories;
  }

  @Selector()
  static newsCategoryOptions(state: NewsCategoryStateModel) {
    return state.newsCategories.map((newsCategory) => ({
      label: newsCategory.name,
      value: newsCategory.id,
    }));
  }

  @Selector()
  static newsCategoriesById(state: NewsCategoryStateModel) {
    return (newsCategoriesId: number | null) => state.newsCategories.find((newsCategories) => newsCategories.id === newsCategoriesId)
  }

  @Selector()
  static fetching(state: NewsCategoryStateModel) {
    return state.fetching;
  }

  @Action(NewsCategories.GetNewsCategories)
  loadNewsCategories({ patchState }: StateContext<NewsCategoryStateModel>) {
    patchState({ fetching: true });
    return this.newsCategoriesService.getNewsCategories().pipe(
      tap((newsCategories) => {
        patchState({
          fetching: false,
          newsCategories,
        });
      })
    );
  }

  @Action(NewsCategories.CreateNewsCategory)
  createNewsCategory(ctx: StateContext<NewsCategoryStateModel>, action: NewsCategories.CreateNewsCategory) {
    return this.newsCategoriesService.createNewsCategory(action.createNewsCategoryInput).pipe(
      tap((newNewsCategory) => {
        const state = ctx.getState()

        ctx.patchState({
          newsCategories: [...state.newsCategories!, newNewsCategory]
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'NewsCategory created successfully.'
        })
      })
    )
  }

  @Action(NewsCategories.UpdateNewsCategory)
  updateNewsCategory(ctx: StateContext<NewsCategoryStateModel>, action: NewsCategories.UpdateNewsCategory) {
    return this.newsCategoriesService.updateNewsCategory(action.updateNewsCategoryInput).pipe(
      tap((updatedNewsCategory) => {
        const state = ctx.getState()

        ctx.patchState({
          newsCategories: state.newsCategories!.map((newsCategories) => newsCategories.id === updatedNewsCategory.id ? updatedNewsCategory : newsCategories)
        })

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'NewsCategory updated successfully.'
        })
      })
    )
  }

  @Action(NewsCategories.RemoveNewsCategory)
  removeNewsCategory(ctx: StateContext<NewsCategoryStateModel>, action: NewsCategories.RemoveNewsCategory) {
    return this.newsCategoriesService.removeNewsCategory(action.newsCategoriesId).pipe(
      tap({
        next: (removedNewsCategory) => {
          const state = ctx.getState()

          ctx.patchState({
            newsCategories: state.newsCategories!.filter((newsCategories) => newsCategories.id !== removedNewsCategory.id)
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'NewsCategory deleted successfully.'
          });
        },
        error: (err) => {
          console.error(err)
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Delete newsCategories failed.'
          })
        }
      })
    )
  }
}
