import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RippleModule} from 'primeng/ripple';
import {SkeletonModule} from "primeng/skeleton";
import {DropdownModule} from "primeng/dropdown";
import {AvatarModule} from 'primeng/avatar';

import {AppConfigModule} from './config/app.config.module';
import {AppLayoutComponent} from './app.layout.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppSidebarComponent} from './app.sidebar.component';
import {AppTopbarComponent} from './app.topbar.component';
import {AppProfileSidebarComponent} from './app.profilesidebar.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {UserSettingComponent} from './user-setting/user-setting.component';
import {BlankLayoutComponent} from './blank.layout.component';
import {ButtonModule} from "primeng/button";
import {DividerModule} from "primeng/divider";
import { SearchBarComponent } from '../search-result/components/search-bar/search-bar.component';
import { SearchResultModule } from '../search-result/search-result.module';
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    AppLayoutComponent,
    AppBreadcrumbComponent,
    AppSidebarComponent,
    AppTopbarComponent,
    AppProfileSidebarComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    UserSettingComponent,
    BlankLayoutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    AppConfigModule,
    SkeletonModule,
    DropdownModule,
    AvatarModule,
    ButtonModule,
    DividerModule,
    SearchResultModule,
    SharedModule
],
  exports: [
    UserSettingComponent,
    BlankLayoutComponent
  ]
})
export class AppLayoutModule { }
