import {NgModule, SecurityContext} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {RootRoutingModule} from './root-routing.module';
import {RootComponent} from './root.component';
import {GraphQLModule} from '../shared/graphql/graphql.module';
import {HttpClientModule} from '@angular/common/http';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {AppLayoutModule} from '../layout/app.layout.module';
import {NgxsModule} from '@ngxs/store';
import {RootState} from '../state/root/root.state';
import {RouterState} from '../state/router/router.state';
import {environment} from '../../environments/environment';
import {AuthState} from '../state/auth/auth.state';
import {CurrentUserState} from '../state/current_user/current_user.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {StorageState} from '../state/storage/storage.state';
import {ProductsState} from '../state/products/products.state';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {OrgState} from '../org/org-state/org.state';
import {STORAGE_STATE} from '../state';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {AdminUserState} from '../administration/state/user/admin-user.state';
import {AdminOrgState} from '../administration/state/org/admin-org.state';
import {LocalDatasetsState} from '../state/local_datasets/local_datasets.state';
import {SpecialAnalysisState} from '../state/special_analysis/special_analysis.state';
import {SettingsState} from '../state/settings/settings.state';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NewsState} from '../state/news/news.state';
import {SharedModule} from "../shared/shared.module";
import {ProgressBarModule} from 'primeng/progressbar';
import { MarkdownModule } from 'ngx-markdown';
import { NewsCategoriesState } from '../state/news_categories/news_categories.state';

@NgModule({
  declarations: [RootComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RootRoutingModule,
        GraphQLModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppLayoutModule,
        ButtonModule,
        NgxsModule.forRoot(
            [
                RootState,
                RouterState,
                AuthState,
                CurrentUserState,
                StorageState,
                ProductsState,
                OrgState,
                AdminUserState,
                AdminOrgState,
                LocalDatasetsState,
                SpecialAnalysisState,
                SettingsState,
                NewsState,
                NewsCategoriesState,
            ],
            {developmentMode: !environment.production}
        ),
        NgxsLoggerPluginModule.forRoot(
            {
                disabled: environment.production,
            }
        ),
        NgxsStoragePluginModule.forRoot({key: STORAGE_STATE}),
        NgxsRouterPluginModule.forRoot(),
        ToastModule,
        ConfirmDialogModule,
        FontAwesomeModule,
        SharedModule,
        ProgressBarModule,
        MarkdownModule.forRoot({ sanitize: SecurityContext.STYLE }),
    ],
  providers: [MessageService, ConfirmationService],
  bootstrap: [RootComponent],
})
export class RootModule {}
