import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductsState } from 'src/app/state/products/products.state';
import { Product } from '../../graphql/generated/graphql';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Products } from 'src/app/state/products/products.action';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent {
  @Select(ProductsState.accessibleProducts) accessibleProducts$!: Observable<Product[] | undefined>;
  @Select(ProductsState.productsLoading) productsLoading$!: Observable<boolean>;
  @Select(ProductsState.selectedProduct) selectedProduct$!: Observable<Product | undefined>;

  constructor(private store: Store) {

  }

  setProduct(event?: DropdownChangeEvent) {
    this.store.dispatch(new Products.SelectProductById(event?.value.id));
  }

  // Used for debugging purpose to clear the selected product
  // Paste these code into product dropdown in the template file
  // [showClear]="true" (onClear)="unsetProduct()"
  unsetProduct() {
    this.store.dispatch(new Products.ClearSelectedProduct());
  }
}
