import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  CreateNewsInput,
  News,
  NewsCreateGQL,
  NewsListGQL,
  NewsRemoveGQL,
  NewsUpdateGQL,
  UpdateNewsInput,
} from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(
    private newsListGQL: NewsListGQL,
    private newsCreateGQL: NewsCreateGQL,
    private newsUpdateGQL: NewsUpdateGQL,
    private newsRemoveGQL: NewsRemoveGQL,
  ) {}

  getNewsList() {
    return this.newsListGQL
      .fetch()
      .pipe(map((response) => response.data.newsList as News[]));
  }

  createNews(newsCreateInput: CreateNewsInput) {
    return this.newsCreateGQL
      .mutate({ newsCreateInput })
      .pipe(map((result) => result.data?.newsCreate as News));
  }

  updateNews(newsUpdateInput: UpdateNewsInput) {
    return this.newsUpdateGQL
      .mutate({ newsUpdateInput })
      .pipe(map((result) => result.data?.newsUpdate as News));
  }

  removeNews(newsRemoveId: number) {
    return this.newsRemoveGQL
      .mutate({ newsRemoveId })
      .pipe(map((result) => result.data?.newsRemove as News));
  }
}
