import { CreateNewsCategoryInput, UpdateNewsCategoryInput } from "src/app/shared/graphql/generated/graphql";

export namespace NewsCategories {
  export class GetNewsCategories {
    static readonly type = '[NewsCategories] GetNewsCategories';
  }

  export class CreateNewsCategory {
    static readonly type = '[NewsCategories] CreateNewsCategory';
    constructor(public createNewsCategoryInput: CreateNewsCategoryInput) { }
  }

  export class UpdateNewsCategory {
    static readonly type = '[NewsCategories] UpdateNewsCategory';
    constructor(public updateNewsCategoryInput: UpdateNewsCategoryInput) { }
  }

  export class RemoveNewsCategory {
    static readonly type = '[NewsCategories] RemoveNewsCategory';
    constructor(public newsCategoriesId: number) { }
  }

}
