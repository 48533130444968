<div>
  <p-dropdown
    *ngIf="!(productsLoading$ | async); else productsLoading"
    [options]="(accessibleProducts$ | async)!"
    [ngModel]="selectedProduct$ | async"
    (onChange)="setProduct($event)"
    optionLabel="name"
    placeholder="Produkt wählen"
    styleClass="w-full"
    scrollHeight="250px"
  ></p-dropdown>
  <ng-template #productsLoading>
    <p-skeleton height="42px"></p-skeleton>
  </ng-template>
</div>
