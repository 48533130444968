import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  CreateNewsCategoryInput,
  NewsCategory,
  NewsCategoryCreateGQL,
  NewsCategoriesGQL,
  NewsCategoryRemoveGQL,
  NewsCategoryUpdateGQL,
  UpdateNewsCategoryInput,
} from '../graphql/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class NewsCategoryService {
  constructor(
    private newsCategoriesGQL: NewsCategoriesGQL,
    private newsCategoryCreateGQL: NewsCategoryCreateGQL,
    private newsCategoryUpdateGQL: NewsCategoryUpdateGQL,
    private newsCategoryRemoveGQL: NewsCategoryRemoveGQL,
  ) {}

  getNewsCategories() {
    return this.newsCategoriesGQL
      .fetch()
      .pipe(map((response) => response.data.newsCategories as NewsCategory[]));
  }

  createNewsCategory(newsCategoryCreateInput: CreateNewsCategoryInput) {
    return this.newsCategoryCreateGQL
      .mutate({ newsCategoryCreateInput })
      .pipe(map((result) => result.data?.newsCategoryCreate as NewsCategory));
  }

  updateNewsCategory(newsCategoryUpdateInput: UpdateNewsCategoryInput) {
    return this.newsCategoryUpdateGQL
      .mutate({ newsCategoryUpdateInput })
      .pipe(map((result) => result.data?.newsCategoryUpdate as NewsCategory));
  }

  removeNewsCategory(newsCategoryRemoveId: number) {
    return this.newsCategoryRemoveGQL
      .mutate({ newsCategoryRemoveId })
      .pipe(map((result) => result.data?.newsCategoryRemove as NewsCategory));
  }
}
